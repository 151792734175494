import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Diary_t from '../../components/illustrations/Diary_t';
import Diary_l1 from '../../components/illustrations/Diary_l1';
import Diary_l2 from '../../components/illustrations/Diary_l2';
import Diary_r1 from '../../components/illustrations/Diary_r1';
import Diary_r2 from '../../components/illustrations/Diary_r2';

function IntroPage() {
  return (
    <Layout bg='bg-faith-pink'>
      <SEO
        keywords={[
          `work-life balance`,
          `parent support`,
          `lone parent`,
          `childcare`,
          'ireland',
        ]}
        title="Faith's Diary"
      />
      <section className='bg-faith-pink max-h-screen w-full text-black px-4  '>
        <div className='  h-screen mx-auto text-center'>
          <div className='flex flex-row w-full '>
            <div className='-mt-12 md:mt-0 md:w-full mx-auto '>
              <Diary_t className='max-w-full' />
            </div>
          </div>
          <div className='flex flex-row w-full justify-between'>
            <div className='hidden md:block  mx-auto w-auto h-auto'>
              {' '}
              <Diary_l1 />
            </div>
            <div className='two -mt-12 md:mt-0 mx-auto'>
              <div className=''>
                <h1 className='text-4xl md:text-6xl capitalize font-serif day-heading-bg'>
                  Faith&apos;s Diary
                </h1>
              </div>
              <div className=''>
                <div className='bg-faith-pink md:bg-none md:text-xl max-w-2xl py-4 px-8 '>
                  <p>
                    Faith wrote a diary for one week, and she wants to share it
                    with you. Read it now to get a sense of what it’s like to
                    walk a mile in her shoes...
                  </p>
                </div>
              </div>

              <div className='my-3 md:my-6'>
                {' '}
                <Link to='/diary/monday'>
                  <button className='faith-btn'>
                    <span>Read what Faith has to say</span>
                  </button>
                </Link>
              </div>
            </div>
            <div className='hidden md:block  mx-auto h-40'>
              <Diary_r1 />
            </div>
          </div>
          <div className='flex flex-row justify-between h-auto'>
            <div className='hidden md:block   mx-auto '>
              <Diary_l2 />
            </div>
            <div className='hidden md:block   mx-auto'>
              <Diary_r2 />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IntroPage;
